import React from "react";
import AutocompleteStyled from "./AutocompleteStyled";
import { useGenres } from "@/lib/api";
import { Skeleton } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

export const GenreSelector = ({
  name = "genre",
  defaultValue,
  value,
  onChange,
  variant,
  label,
  hint,
  placeholder,
  required = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const { genres, isLoading } = useGenres();

  const handleSelected = (event, value) => {
    event.target.name = name;
    event.target.value = value?.id;

    if (onChange) {
      onChange(event, value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const event = {};
      event.target = {};
      event.target.name = name;
      event.target.value =
        typeof defaultValue === "object"
          ? defaultValue.id + ""
          : defaultValue + "";

      if (onChange) {
        onChange(event, defaultValue);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      const event = {};
      event.target = {};
      event.target.name = name;
      event.target.value = typeof value === "object" ? value.id : value;

      if (onChange) {
        onChange(event, value);
      }
    }
  }, [value]);

  useEffect(() => {
    if (!isLoading && defaultValue) {
      for (const g of genres) {
        if (g.id === defaultValue) {
          const event = {};
          event.target = {};
          event.target.name = name;
          event.target.value = defaultValue;

          setSelectedValue(g);
          handleSelected(event, g);

          break;
        }
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <AutocompleteStyled
      name={name}
      variant={variant}
      label={label}
      hint={hint}
      options={genres}
      placeholder={placeholder}
      defaultValue={
        typeof defaultValue === "object"
          ? defaultValue
          : genres.find((g) => g.id + "" === defaultValue + "")
      }
      value={
        typeof value === "object" ? value : genres.find((g) => g.id === value)
      }
      onChange={handleSelected}
      getOptionLabel={(option) => `${option.title}`}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      required={required}
    />
  );
};
