import { NextSeo } from "next-seo";

export default function PageHead({ page }) {
  return (
    <NextSeo
      title={page?.seo_title || page?.title || page?.headline}
      description={
        page?.seo_description ||
        (typeof page?.content === "string" && page?.content?.substring(0, 155))
      }
      noindex={page?.noindex}
      nofollow={page?.noindex}
      openGraph={{
        title: page?.seo_title || page?.title || page?.headline,
        description:
          page?.seo_description ||
          (typeof page?.content === "string" &&
            page?.content?.substring(0, 155)),
      }}
    />
  );
}
