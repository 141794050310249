import { NewsCard } from "@/components/news/NewsCard";
import theme from "@/styles/theme";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "next/link";

export default function NewsTeaserDisplay({
  articles,
  linkToCategory = undefined,
  newsToExclude,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 1, md: 3 }}>
        {articles
          ?.filter(
            (a) => newsToExclude == null || newsToExclude.id + "" !== a.id + "",
          )
          .slice(0, 3)
          .map((article) => (
            <Grid
              key={article.slug}
              item
              xs={12}
              md={4}
              sx={{
                "&.MuiGrid-root:not(:nth-of-type(1))": {
                  [theme.breakpoints.only("xs")]: {
                    display: "none",
                  },
                },
              }}
            >
              <NewsCard key={article.slug} article={article} />
            </Grid>
          ))}
        <Grid
          item
          align="center"
          xs={12}
          sx={{ [theme.breakpoints.up("sm")]: { mt: 3 } }}
        >
          <Button
            href={`/news/${
              linkToCategory ? `?category=${linkToCategory}` : ""
            }`}
            component={Link}
            variant="outlined-secondary"
            sx={{
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                width: "auto",
              },
            }}
          >
            Zum Newsarchiv
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
