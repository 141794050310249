import theme from "../styles/theme";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea, Skeleton } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { ImageAsset } from "@/components/ImageAsset";
import { PlayIconCircle } from "@/components/icons/PlayIconCircle";
import { useSongOfTheWeek } from "@/lib/api";
import { responsiveImage } from "@/lib/helper";
import { basierCirleBold } from "@/styles/fonts";
import { useRecoilState } from "recoil";
import { nextTrackState, playerPlaylistState } from "@/context/states";

export const TopSong = ({}) => {
  const { songOfTheWeek, isLoading } = useSongOfTheWeek();
  const [playerPlaylist, setPlayerPlaylist] =
    useRecoilState(playerPlaylistState);
  const [nextTrack, setNextTrack] = useRecoilState(nextTrackState);

  const handlePlay = (event) => {
    setPlayerPlaylist([songOfTheWeek.song]);
    setNextTrack(songOfTheWeek.song);
  };

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Divider
          sx={{
            "&.MuiDivider-root": {
              mt: 0,
              mb: 1,
            },
          }}
        >
          oder
        </Divider>
        <Button
          fullWidth
          variant="outlined-secondary"
          startIcon={<PlayCircleIcon />}
          onClick={handlePlay}
        >
          Song der Woche
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          [theme.breakpoints.up("xl")]: {
            pt: 2,
            pb: 2.5,
          },
        }}
        onClick={handlePlay}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          Song der Woche
        </Typography>
        {isLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.800" }}
            variant="rectangular"
            height={184}
          />
        ) : (
          <Card align="center" variant="CardFeatureTeaser">
            <ImageAsset
              url={responsiveImage(songOfTheWeek?.song.band.photo.formats, 891)}
              local={true}
              layout="fill"
            />
            <CardActionArea>
              <Grid container sx={{ height: "100%" }}>
                <SvgIcon
                  color="secondary"
                  sx={{
                    fontSize: 60,
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    m: "auto",
                  }}
                >
                  <PlayIconCircle />
                </SvgIcon>
                <Grid item xs={12}>
                  <CardContent
                    sx={{
                      height: "100%",
                      position: "relative",
                      "&.MuiCardContent-root": {
                        "&:last-of-type": {
                          paddingBottom: "1rem",
                        },
                      },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        alignContent: "space-between",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: "1.375rem",
                          }}
                        >
                          {songOfTheWeek?.song.band.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: basierCirleBold.style.fontWeight,
                            fontFamily: basierCirleBold.style.fontFamily,
                          }}
                          variant="subtitle2"
                          className="songtitle"
                        >
                          {songOfTheWeek?.song.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        )}
      </Box>
    </>
  );
};
