import React, { useState } from "react";
import theme from "../styles/theme";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ImageAsset } from "@/components/ImageAsset";
import {Typography} from "@mui/material";
import Link from "next/link";

const presenter = {
  display: "none",
  position: "absolute",
  background:
    "linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%);",
  width: "100%",
  height: "6.5rem",
  zIndex: "500",
  bottom: "0",
  "@media (min-width:900px)": {
    display: "block",
  },
  "& .wrapper": {
    height: "100%",
    position: "relative",
    "& .text": {
      margin: "1rem",
      color: theme.palette.common.white,
      textTransform: "uppercase",
      letterSpacing: ".21875rem",
      fontSize: ".75rem",
    },
    "& .create-music": {
      width: "202px",
      transform: "translateY(-1rem)",
    },
    "& .new-heimat-sounds": {
      height: "3.25rem",
      width: "3.25rem",
    },
  },
};

export const Hero = ({ content, ...props }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        height: 200,
        position: "relative",
        [theme.breakpoints.up("sm")]: {
          height: 540,
        },
      }}
    >
      <Box sx={presenter}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="wrapper"
        >
          <Grid item lg={8}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item lg="auto">
                <Typography className="text">Präsentiert von</Typography>
              </Grid>
                <Grid item lg="auto" className="create-music">
                  <Link href="/netzwerk/ueber-uns/createmusic" passHref>

                    <ImageAsset
                        layout="responsive"
                        local={true}
                        url="/img/presenter-create_music.svg"
                        width={202}
                        height={44}
                        alt="create music"
                    />

                  </Link>
                </Grid>

              <Grid item lg="auto">
                <Typography className="text">und</Typography>
              </Grid>
                <Grid item lg="auto" className="new-heimat-sounds">
                  <Link href="/netzwerk/ueber-uns/newheimatsounds" passHref>

                    <ImageAsset
                        layout="responsive"
                        local={true}
                        url="/img/presenter-new_heimat_sounds.svg"
                        width={48}
                        height={48}
                        alt="New Heimat Sounds"
                    />

                  </Link>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ImageAsset url={content?.hero_backgroundimage?.url} layout="fill" priority={content?.hero_image?.url ? false : true} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          [theme.breakpoints.up("lg")]: {
            height: "calc(100% - 6.5rem)",
          },
        }}
      >
        <Grid item xs={10} md={6} lg={6}>
          <Box sx={{maxWidth: "38rem", mx: "auto"}}>
            <ImageAsset
              layout="responsive"
              local={true}
              url={content?.hero_image?.url}
              priority={true}
              width={150}
              height={72}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
