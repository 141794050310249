import theme from "@/styles/theme";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {grey} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import {FormattedDate} from "react-intl";
import {futuraNextCondensedExtraBold} from "@/styles/fonts";
import {StrapiImageAsset} from "@/components/StrapiImageAsset";

export const NewsCard = ({article}) => {
    return (
        <Card
            component={Link}
            href={`/news/${article.slug}`}
            elevation={0}
            sx={{
                backgroundColor: "transparent",
                height: "100%",
                textDecoration: "none",
            }}
        >
            <Typography
                variant="h6"
                component="h4"
                color="common.black"
                sx={{
                    fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
                    mb: 1,
                    [theme.breakpoints.up("sm")]: {mb: 3},
                }}
            >
                {article.title}
            </Typography>
            {article.image && (
                <CardMedia
                    height="192"
                    sx={{
                        bgcolor: grey[200],
                        position: "relative",
                        aspectRatio: "16/9",
                    }}
                >
                    <StrapiImageAsset
                        strapiImageObject={article?.image}
                        layout="fill"
                        width={400}
                        height={225}
                    />
                </CardMedia>
            )}
            <CardContent
                sx={{
                    px: 0,
                    [theme.breakpoints.only("xs")]: {pt: 1},
                    "&.MuiCardContent-root": {
                        paddingBottom: 0,
                    },
                }}
            >
                <Typography variant="body2">{article.teaser}</Typography>
                <Typography
                    variant="caption"
                    sx={{
                        mt: 0.5,
                        color: grey[500],
                        display: "block",
                        [theme.breakpoints.up("sm")]: {mt: 2},
                    }}
                >
                    <FormattedDate
                        value={
                            article.date_display
                                ? article.date_display
                                : article.published_at
                        }
                    />
                </Typography>
            </CardContent>
        </Card>
    );
};
