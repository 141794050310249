import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";
import { useState } from "react";
import theme from "../styles/theme";
import { GenreSelector } from "./GenreSelector";
import LinkStyled from "./LinkStyled";
import { RegionSelector } from "./RegionSelector";

export const GenreRegionFilter = ({ onChange, ...props }) => {
  const router = useRouter();
  const [filterValues, setFilterValues] = useState({});

  const setFilter = (type, value) => {
    setFilterValues({ ...filterValues, [type]: value?.id });
  };

  const openFilterPage = () => {
    const genreQuery = filterValues.genre
      ? `genre=${filterValues.genre}`
      : null;
    const regionQuery = filterValues.region
      ? `region=${filterValues.region}`
      : "";

    router.push(
      `/musik/suchen?${genreQuery ? `${genreQuery}&` : ""}${regionQuery}`,
    );
  };

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.up("xl")]: {
            pt: 2,
            pb: 2.5,
          },
        }}
      >
        <Typography
          variant="h6"
          align="center"
          sx={{ mb: 2, display: { xs: "none", md: "block" } }}
        >
          Musik suchen & finden
        </Typography>
        <Card
          align="center"
          elevation={0}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <CardContent
            sx={{
              p: 0,
              "&.MuiCardContent-root": {
                "&:last-of-type": {
                  paddingBottom: 0,
                },
              },
              "& .MuiInputBase-root": {
                backgroundColor: theme.palette.common.white,
              },
            }}
          >
            <GenreSelector
              variant="dark"
              placeholder="Genre wählen"
              onChange={(event, value) => {
                setFilter("genre", value);
              }}
            />
            <RegionSelector
              variant="dark"
              placeholder="Region wählen"
              onChange={(event, value) => {
                setFilter("region", value);
              }}
            />

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={openFilterPage}
              sx={{
                [theme.breakpoints.up("md")]: {
                  mb: 1,
                },
              }}
            >
              Musik suchen
            </Button>
            <Typography
              variant="caption"
              align="center"
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <LinkStyled href="/musik/suchen" label="Mehr Filter Anzeigen" />
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
