import theme from "../styles/theme";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea, Skeleton } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { ImageAsset } from "@/components/ImageAsset";
import { PlayIconCircle } from "@/components/icons/PlayIconCircle";
import { usePlaylistOfTheWeek } from "@/lib/api";
import { responsiveImage } from "@/lib/helper";
import { useRecoilState } from "recoil";
import { nextTrackState, playerPlaylistState } from "@/context/states";

export const TopPlaylist = ({}) => {
  const { playlistOfTheWeek, isLoading, isError } = usePlaylistOfTheWeek();
  const [nextTrack, setNextTrack] = useRecoilState(nextTrackState);
  const [playerPlaylist, setPlayerPlaylist] =
    useRecoilState(playerPlaylistState);

  const handlePlay = (event) => {
    setPlayerPlaylist(playlistOfTheWeek.playlist.songs);
    setNextTrack(playlistOfTheWeek.playlist.songs[0]);
  };

  return (
    <>
      <Box sx={{ mb: 2, display: { xs: "block", md: "none" } }}>
        <Button
          fullWidth
          variant="outlined-secondary"
          startIcon={<PlayCircleIcon />}
          onClick={handlePlay}
        >
          Playlist der Woche
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          [theme.breakpoints.up("xl")]: {
            pt: 2,
            pb: 2.5,
          },
        }}
        onClick={handlePlay}
      >
        <Typography
          variant="h6"
          align="center"
          sx={{ mb: 2, display: { xs: "none", sm: "block" } }}
        >
          Playlist der Woche
        </Typography>
        {isLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.800" }}
            variant="rectangular"
            height={184}
          />
        ) : (
          <Card align="center" variant="CardFeatureTeaser">
            <ImageAsset
              url={responsiveImage(
                playlistOfTheWeek?.playlist.image?.formats,
                891
              )}
              layout="fill"
            />
            <CardActionArea>
              <Grid container sx={{ height: "100%" }} alignItems="flex-end">
                <SvgIcon
                  color="secondary"
                  sx={{
                    fontSize: 60,
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    m: "auto",
                  }}
                >
                  <PlayIconCircle />
                </SvgIcon>
                <Grid item xs={12}>
                  <CardContent
                    sx={{
                      position: "relative",
                      "&.MuiCardContent-root": {
                        "&:last-of-type": {
                          paddingBottom: "1rem",
                        },
                      },
                    }}
                  >
                    <Typography variant="subtitle2">
                      {playlistOfTheWeek?.playlist.title}
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        )}
      </Box>
    </>
  );
};
