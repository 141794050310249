import Card from "@mui/material/Card";
import theme from "@/styles/theme";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "next/link";
import Button from "@mui/material/Button";
import LinkStyled from "@/components/LinkStyled";
import React, {useContext} from "react";
import AppContext from "@/context/AppContext";

export const HomepageCTABox = ({}) => {
    const {isAuthenticated} = useContext(AppContext);

    return (
        <Card
            align="center"
            sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                display: "none",
                position: "relative",
                "&.MuiPaper-root": {
                    transform: "skewX(-5deg)",
                },
                [theme.breakpoints.up("md")]: {
                    gridColumn: "1 / span 3",
                    display: "block",
                },
                [theme.breakpoints.up("xl")]: {
                    mb: 0,
                    gridColumn: "auto",
                },
            }}
        >
            <Grid
                container
                alignItems="center"
                sx={{
                    height: "100%",
                    transform: "skewX(5deg)",
                }}
            >
                <Grid item xs={12}>
                    <CardContent
                        sx={{
                            "&.MuiCardContent-root": {
                                "&:last-of-type": {
                                    paddingBottom: "1rem",
                                },
                            },
                        }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={4} xl={12}>
                                <Typography
                                    variant="h3"
                                    color="common.white"
                                    align="center"
                                    sx={{
                                        [theme.breakpoints.up("xl")]: {
                                            mb: 2,
                                        },
                                    }}
                                >
                                    {!isAuthenticated ? "Anmelden" : "Dashboard"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} xl={12}>
                                <Typography variant="body1" align="center">
                                    {!isAuthenticated ? "und werde Teil der showcase.nrw" : "Verwalte deine Band(s)"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} xl={12}>
                                <Box
                                    sx={{
                                        zIndex: "0",
                                        position: "relative",
                                    }}
                                >
                                    <Link href={!isAuthenticated ? "/register" : "/secure"} passHref legacyBehavior>
                                        <Button
                                            variant="outlined-white-x"
                                            sx={{
                                                mt: 2,
                                                mb: 1.5,
                                            }}
                                        >
                                            {!isAuthenticated ? "Anmelden" : "Zum Dashboard"}
                                        </Button>
                                    </Link>
                                </Box>
                                {!isAuthenticated ? (
                                    <Box
                                        variant="caption"
                                        sx={{
                                            display: "block",
                                            position: "relative",
                                            fontSize: ".875rem",
                                            "& a": {
                                                "&:link": {
                                                    color: theme.palette.secondary.dark,
                                                    textDecoration: "none",
                                                },
                                                "&:visited": {
                                                    color: theme.palette.secondary.dark,
                                                },
                                                "&:hover": {
                                                    color: theme.palette.secondary.dark,
                                                    opacity: ".75",
                                                },
                                                "&:active": {
                                                    color: theme.palette.secondary.dark,
                                                    opacity: "1",
                                                },
                                            },
                                        }}
                                    >
                                        <LinkStyled
                                            href="/netzwerk/ueber-uns"
                                            label="mehr über showcase.nrw erfahren"
                                        />
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};
