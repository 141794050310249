import { ChevronLeftCircleIcon } from "@/components/icons/ChevronLeftCircleIcon";
import theme from "@/styles/theme";
import { IconButton, SvgIcon, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

const autocomplete = {
  "& .MuiFilledInput-root": {
    padding: ".1875rem 0 .1875rem 1rem",
    minHeight: "42px",
    "& ::placeholder": {
      color: "#1a1a1a",
      opacity: "1!important",
      textTransform: "uppercase",
      fontSize: "1rem",
    },

    "& input": {
      padding: "unset !important",
    },

    "& .MuiChip-root": {
      height: "1.75rem",
      borderRadius: ".25rem",
      color: theme.palette.common.white,
      margin: ".1875rem .5rem .1875rem 0",

      "&::before": {
        content: "''",
        margin: "auto",
        width: "1rem",
        height: "1rem",
        left: "0",
        top: "0",
        bottom: "0",
        position: "absolute",
        transform: "translateX(.5rem)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },

      "& .MuiChip-label": {
        marginLeft: "1rem",
      },
    },

    "& .MuiAutocomplete-option": {
      textAlign: "left",
    },
  },

  "& label": {
    display: "none",
  },
};

export default function AutocompleteStyled({
  options,
  defaultValue,
  label,
  hint,
  placeholder,
  value,
  disabled = false,
  onChange,
  required = false,
  isOptionEqualToValue,
  fatherRegion,
  changeToFather,
  disableCloseOnSelect = false,
  PaperComponent,
  ...props
}) {
  const [isValid, setIsValid] = useState(value ?? defaultValue ?? !required);

  const StyledPopper = styled(Popper)({
    "& ul": {
      maxHeight: "16rem",
    },
    "& li": {
      textAlign: "left",
    },
  });

  useEffect(() => {
    if (!required) {
      setIsValid(true);
    } else {
      setIsValid(value ?? defaultValue ?? false);
    }
  }, [required]);

  const handleSelected = (event, value) => {
    if (required) {
      if (value == null) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(true);
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <>
      {label && (
        <InputLabel
          sx={{
            color: isValid
              ? theme.palette.common.black
              : theme.palette.error.main,
          }}
        >
          {label}
          {required && "*"}{" "}
          {hint && (
            <Typography
              variant="caption"
              component="span"
              sx={{
                "&.MuiTypography-root": {
                  color: isValid ? grey[600] : theme.palette.error.main,
                },
              }}
            >
              ({hint})
            </Typography>
          )}
        </InputLabel>
      )}
      <Autocomplete
        sx={autocomplete}
        options={options}
        getOptionLabel={props.getOptionLabel}
        onChange={handleSelected}
        defaultValue={defaultValue}
        value={value}
        isOptionEqualToValue={isOptionEqualToValue}
        disablePortal={true}
        PopperComponent={StyledPopper}
        PaperComponent={PaperComponent}
        disableCloseOnSelect={disableCloseOnSelect}
        noOptionsText="Keine Ergebnisse"
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            type="text"
            variant="filled"
            placeholder={placeholder || `BITTE WÄHLEN`}
            sx={{
              "& .MuiFilledInput-root": {
                "&::before": {
                  borderColor: isValid ? "transparent" : "#c33132 !important",
                },
              },
              "& .Mui-disabled": {
                backgroundColor: "#F2F2F2 !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: fatherRegion && (
                <>
                  <InputAdornment
                    position="start"
                    sx={{ marginTop: "unset !important" }}
                  >
                    <IconButton
                      onClick={changeToFather}
                      aria-label="Zurück"
                      sx={{ padding: "0" }}
                    >
                      <SvgIcon
                        sx={{
                          color: grey[400],
                          transition: "all .2s ease-in-out",
                          "&:hover": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      >
                        <ChevronLeftCircleIcon />
                      </SvgIcon>
                    </IconButton>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
