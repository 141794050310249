import React, { useState } from "react";
import theme from "../styles/theme";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "next/link";
import {ImageAsset} from "@/components/ImageAsset";
import { responsiveImage } from "@/lib/helper";

export const TeaserboxFullwidth = ({ content }) => {
  return <>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative" }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "block", sm: "none" },
          mt: 2,
        }}
      >
        <Typography
          component="h4"
          variant="h2"
          align="center"
          sx={{
            mb: 2,
          }}
        >
          {content.headline}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          minHeight: "155px",
          position: "relative",
          [theme.breakpoints.up("sm")]: {
            minHeight: "432px",
          },
        }}
      >
        <ImageAsset url={responsiveImage(content.image?.formats, 1920)} layout="fill" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          py: 2,
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
            p: 4,
          },
        }}
      >
        <Box
          sx={{
            mb: 3.5,
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              mb: 2,
              position: "relative",
              zIndex: "2",
              display: { xs: "none", sm: "block" },
            }}
          >
            {content.headline}
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ position: "relative", zIndex: "2" }}
          >
            {content.content}
          </Typography>
        </Box>
        <Link href={content.linktarget} passHref legacyBehavior>
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "relative", zIndex: "2" }}
          >
            {content.button}
          </Button>
        </Link>
      </Grid>
    </Grid>
  </>;
};
